import React, { useState } from 'react'
import { Box, Form, FormField, TextInput, Button, Heading } from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { I18n } from 'galarm-config'
import { useLocation, useNavigate } from 'react-router-dom'
import { validateEmail } from '../utils/validations'
import ErrorProvider from './ErrorProvider'
import {
  arrayRemove,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore'
import { SecondaryText } from 'web-components'

// Very basic implementation at the moment
// Need to add authentication and validation
const ChangeOrganizationOwner = () => {
  const currentEnterpriseAccountId = window.localStorage.getItem(
    'enterpriseAccountId'
  )

  const [value, setValue] = useState({ newOwnerEmail: '' })

  const location = useLocation()
  const navigate = useNavigate()

  const enterpriseAccountId = location.state?.enterpriseAccountId

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const onChangeOwner = async ({ value }) => {
    if (!enterpriseAccountId) {
      addError(I18n.t('enterpriseAccountIdRequired'))
      return
    }

    const { newOwnerEmail } = value
    const enterpriseUserSnapshot = await getDocs(
      query(
        collection(GlobalConfig.firestoreDb, 'enterpriseUsers'),
        where('email', '==', newOwnerEmail)
      )
    )

    if (enterpriseUserSnapshot.empty) {
      addError(I18n.t('enterpriseUserNotFound'))
      return
    }

    const enterpriseUser = enterpriseUserSnapshot.docs[0].data()
    await updateDoc(
      doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', enterpriseAccountId),
      {
        owner: enterpriseUser.id
      }
    )
    await updateDoc(
      doc(GlobalConfig.firestoreDb, 'enterpriseUsers', enterpriseUser.id),
      {
        organizations: arrayRemove(enterpriseAccountId)
      }
    )
    if (currentEnterpriseAccountId === enterpriseAccountId) {
      window.localStorage.removeItem('enterpriseAccountId')
    }

    navigate('/app/account', { replace: true })
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center">
        <Heading level={4} margin={{ left: 'medium' }}>
          {I18n.t('changeOrganizationOwner')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={onChangeOwner}>
            <FormField
              htmlFor="newOwnerEmail"
              name="newOwnerEmail"
              label={
                <SecondaryText weight="500">
                  {I18n.t('newOwnerEmail')}
                </SecondaryText>
              }
              validate={validateEmail}>
              <TextInput id="newOwnerEmail" name="newOwnerEmail" />
            </FormField>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={I18n.t('change')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default ChangeOrganizationOwner
