import React, { useRef, useState } from 'react'
import { Box, Form, FormField, TextInput, Button, Heading } from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { validateEmail } from '../utils/validations'
import ErrorProvider from './ErrorProvider'
import ReCAPTCHA from 'react-google-recaptcha'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useNavigate } from 'react-router-dom'
import { SecondaryText } from 'web-components'

const ForgotPassword = () => {
  const [value, setValue] = useState({ email: '' })
  const navigate = useNavigate()

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const captchaRef = useRef(null)

  const onSendPasswordResetEmail = async ({ value }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('sendingPasswordResetEmail'),
      closeable: false
    })

    try {
      const token = captchaRef.current.getValue()
      captchaRef.current.reset()

      const functions = getFunctions()
      const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha')
      const result = await verifyRecaptcha({ token })
      const verificationResponse = result.data

      if (!verificationResponse.success) {
        addError(
          I18n.t('recaptchaFailed', {
            error: verificationResponse['error-codes'].join(', ')
          })
        )
        GlobalConfig.hideProgress()
        return
      }

      const { email } = value
      const sendPasswordResetLink = httpsCallable(
        functions,
        'sendPasswordResetLink'
      )
      await sendPasswordResetLink({
        email: email,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })

      GlobalConfig.hideProgress()

      GlobalConfig.showTransientAlert({
        message: I18n.t('passwordResetEmailSent'),
        type: 'success',
        duration: Constants.AlertDurations.VERY_LONG
      })

      navigate('/login', { replace: true })
    } catch (error) {
      // const errorCode = error.code
      // const errorMessage = error.message
      console.error('onSendPasswordResetEmail', error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center">
        <Heading level={4} margin={{ left: 'medium' }}>
          {I18n.t('forgotPasswordTitle')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={onSendPasswordResetEmail}>
            <FormField
              htmlFor="email"
              name="email"
              label={
                <SecondaryText weight="500">{I18n.t('email')}</SecondaryText>
              }
              validate={validateEmail}>
              <TextInput id="email" name="email" />
            </FormField>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                asyncScriptOnLoad={() => {}}
              />
            </Box>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={I18n.t('sendPasswordResetEmail')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default ForgotPassword
