import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { Box, Button, Form, FormField, Heading, TextInput } from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  stringNotEmpty,
  validateConfirmedPassword,
  validatePassword
} from '../utils/validations'
import ErrorProvider from './ErrorProvider'
import UserContext from './UserContext'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import { SecondaryText } from 'web-components'

const ResetTempPassword = () => {
  const { user } = useContext(UserContext)

  const [value, setValue] = useState({
    tempPassword: '',
    newPassword: '',
    confirmPassword: ''
  })

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const navigate = useNavigate()

  useEffect(() => {
    async function loadAuthData() {
      const auth = getAuth()
      const userAuthData = await auth.currentUser.getIdTokenResult()

      if (!userAuthData.claims?.isTempPassword) {
        navigate('/app', { replace: true })
        return
      }
    }
    loadAuthData()
  }, [user])

  const onChangePassword = async ({ value }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('resettingTempPassword'),
      closeable: false
    })

    const { tempPassword, newPassword } = value
    try {
      const functions = getFunctions()
      const resetTempPassword = httpsCallable(functions, 'resetTempPassword')
      await resetTempPassword({
        tempPassword,
        newPassword
      })

      navigate('/app', { replace: true })
      GlobalConfig.hideProgress()
    } catch (error) {
      console.error('Unable to reset temp password', error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center">
        <Heading level={4} margin={{ left: 'medium' }}>
          {I18n.t('resetTempPassword')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={onChangePassword}>
            <FormField
              htmlFor="tempPassword"
              name="tempPassword"
              label={
                <SecondaryText weight="500">
                  {I18n.t('tempPassword')}
                </SecondaryText>
              }
              validate={stringNotEmpty.bind(null, I18n.t('tempPassword'))}>
              <TextInput
                type="password"
                id="tempPassword"
                name="tempPassword"
              />
            </FormField>
            <FormField
              htmlFor="newPassword"
              name="newPassword"
              label={
                <SecondaryText weight="500">
                  {I18n.t('newPassword')}
                </SecondaryText>
              }
              validate={validatePassword}>
              <TextInput type="password" id="newPassword" name="newPassword" />
            </FormField>
            <FormField
              htmlFor="confirmPassword"
              name="confirmPassword"
              label={
                <SecondaryText weight="500">
                  {I18n.t('confirmPassword')}
                </SecondaryText>
              }
              validate={(confirmPassword, formValue) =>
                validateConfirmedPassword(
                  formValue.newPassword,
                  confirmPassword
                )
              }>
              <TextInput
                type="password"
                id="confirmPassword"
                name="confirmPassword"
              />
            </FormField>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={I18n.t('reset')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default ResetTempPassword
