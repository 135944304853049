import {
  Accordion,
  AccordionPanel,
  Box,
  Heading,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text
} from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n, colors } from 'galarm-config'
import { Copy } from 'grommet-icons'
import UserContext from './UserContext'
import { doc, getDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import ErrorProvider from './ErrorProvider'
import { isNumber } from 'lodash'
import { Link } from 'react-router-dom'
import {
  createCloudFunctionUrl,
  createCloudFunctionUrlWithBasicAuth
} from '../utils/utils'
import { SecondaryText } from 'web-components'

const ViewApiCode = ({ alert }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const { user } = useContext(UserContext)

  const [apiKey, setApiKey] = useState('')
  const [authToken, setAuthToken] = useState('')

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const [accordionIndex, setAccordionIndex] = useState(-1)

  useEffect(() => {
    async function loadAuthData() {
      try {
        GlobalConfig.showProgress({
          state: Constants.ProgressStates.IN_PROGRESS,
          message: I18n.t('loadingAuthInfo'),
          closeable: false
        })
        const enterpriseUser = await getDoc(
          doc(GlobalConfig.firestoreDb, 'enterpriseUsers', user.uid)
        ).then(doc => doc.data())
        if (!enterpriseUser) {
          addError('Unable to find user account')
          GlobalConfig.hideProgress()
          return
        }

        const functions = getFunctions()

        if (enterpriseUser.apiKey) {
          setApiKey(enterpriseUser.apiKey)
        } else {
          const createEnterpriseApiKey = httpsCallable(
            functions,
            'createEnterpriseApiKey'
          )
          const apiKeyResult = await createEnterpriseApiKey()
          setApiKey(apiKeyResult.data.apiKey)
        }

        if (enterpriseUser.authToken) {
          setAuthToken(enterpriseUser.authToken)
        } else {
          const getGalarmApiAuthToken = httpsCallable(
            functions,
            'getGalarmApiAuthToken'
          )
          const authTokenResult = await getGalarmApiAuthToken()
          setAuthToken(authTokenResult.data.token)
        }
        GlobalConfig.hideProgress()
      } catch (error) {
        addError(error.message)
        GlobalConfig.hideProgress()
      }
    }
    loadAuthData()
  }, [])

  useEffect(() => {
    if (alert.preferredSourceForIncidents) {
      const index = codes.findIndex(
        code => code.id === alert.preferredSourceForIncidents
      )
      if (index !== -1) {
        setAccordionIndex(index)
      }
    }
  }, [alert])

  const cloudFunctionUrl = createCloudFunctionUrl('galarmapi')
  const cloudFunctionUrlWithBasicAuth = createCloudFunctionUrlWithBasicAuth(
    'galarmapi',
    user.email,
    apiKey
  )
  const genericData = {
    alertId: alert.id,
    enterpriseAccountId
  }

  const datadogData = {
    alertId: alert.id,
    enterpriseAccountId
  }

  const curlCommandBasicAuth = `curl --location --request POST \\
    '${cloudFunctionUrl}/incidents/common' \\
    -u "${user.email}:${apiKey}" \\
    --header 'Content-Type: application/json' \\
    --data-raw '${JSON.stringify(genericData, undefined, 2)}'`

  const curlCommandBearerAuth = `curl --location --request POST \\
    '${cloudFunctionUrl}/incidents/common' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer ${authToken}' \\
    --data-raw '${JSON.stringify(genericData, undefined, 2)}'`

  const curlCommandNoDataBasicAuth = `curl --location --request POST \\
    '${cloudFunctionUrl}/incidents/common/${enterpriseAccountId}/${alert.id}' \\
    -u "${user.email}:${apiKey}" \\
    --header 'Content-Type: application/json'`

  const curlCommandNoDataBearerAuth = `curl --location --request POST \\
    '${cloudFunctionUrl}/incidents/common/${enterpriseAccountId}/${alert.id}' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer ${authToken}'`

  const curlCommandOptionalFieldsExplanation = [
    {
      key: 'message',
      value:
        'You can add additional details about the incident in this field. It will appear under "Notes" in the alarm sent for the incident.'
    },
    {
      key: 'sourceIncidentId',
      value:
        'You can specify an ID for the incident that can be used to detect duplicate incidents and not send alarms for them. It can also be used to identify incidents for "recover" type events.'
    },
    {
      key: 'type',
      value:
        'One of "trigger" or "recover". "trigger" sends a new incident alarm unless it is a duplicate incident. "recover" will automatically close the incident and stop sending further alarms for the incident. Defaults to "trigger".'
    }
  ]

  const datadogOptionalFieldsExplanation = [
    {
      key: 'message',
      value:
        'You can add additional details about the incident in this field. It will appear under "Notes" in the alarm sent for the incident.'
    },
    {
      key: 'sourceIncidentId',
      value:
        'You can specify an ID for the incident that can be used to detect duplicate incidents and not send alarms for them. It can also be used to identify incidents for "Recovered" type events.'
    },
    {
      key: 'type',
      value:
        'Event type from Datadog. One of "Recovered, Triggered/Re-Triggered, No Data/Re-No Data, Warn/Re-Warn, Renotify". Everything except "Recovered" triggers a new alert. "Recovered" will automatically close the incident and stop sending further alerts for the incident. Defaults to "Triggered".'
    }
  ]

  const awsSnsEndpoint = `${cloudFunctionUrlWithBasicAuth}/incidents/sns/${enterpriseAccountId}/${alert.id}`

  const datadogEndpointUrl = `${cloudFunctionUrlWithBasicAuth}/incidents/datadog`
  const datadogEndpointData = `${JSON.stringify(datadogData, undefined, 2)}`

  const codes = [
    {
      id: Constants.SOURCES_FOR_INCIDENTS.CURL_WITH_DATA_BASIC_AUTH,
      text: 'Curl With Data Basic Auth',

      type: 'list',
      instructions: [
        {
          text: `Use the following curl command:`,
          code: curlCommandBasicAuth,
          type: 'text'
        },
        {
          text: 'Optional fields in the data:',
          type: 'text',
          keyValueList: curlCommandOptionalFieldsExplanation
        }
      ]
    },
    {
      id: Constants.SOURCES_FOR_INCIDENTS.CURL_WITH_DATA_BEARER_TOKEN,
      text: 'Curl With Data Bearer Token',
      type: 'list',
      instructions: [
        {
          text: `Use the following curl command:`,
          code: curlCommandBearerAuth,
          type: 'text'
        },
        {
          text: 'Optional fields in the data:',
          type: 'text',
          keyValueList: curlCommandOptionalFieldsExplanation
        }
      ]
    },
    {
      id: Constants.SOURCES_FOR_INCIDENTS.CURL_NO_DATA_BASIC_AUTH,
      text: 'Curl No Data Basic Auth',
      type: 'list',
      instructions: [
        {
          text: `Use the following curl command:`,
          code: curlCommandNoDataBasicAuth,
          type: 'text'
        }
      ]
    },
    {
      id: Constants.SOURCES_FOR_INCIDENTS.CURL_NO_DATA_BEARER_TOKEN,
      text: 'Curl No Data Bearer Token',
      type: 'list',
      instructions: [
        {
          text: `Use the following curl command:`,
          code: curlCommandNoDataBearerAuth,
          type: 'text'
        }
      ]
    },
    {
      id: Constants.SOURCES_FOR_INCIDENTS.AWS_SNS,
      text: 'AWS SNS',
      type: 'list',
      instructions: [
        {
          text: 'Open Amazon SNS consoole',
          type: 'text'
        },
        {
          text: 'Choose the topic for which you want to be alerted',
          type: 'text'
        },
        {
          text: 'Tap on "Create subscription" and choose "Protocol" as "HTTPS"',
          type: 'text'
        },
        {
          text: 'Use the following as the "Endpoint"',
          code: awsSnsEndpoint,
          type: 'text'
        },
        {
          text: 'Fill other sections as needed and tap on "Create subscription"',
          type: 'text'
        }
      ]
    },
    {
      id: Constants.SOURCES_FOR_INCIDENTS.DATA_DOG,
      text: 'Datadog',
      type: 'list',
      instructions: [
        {
          text: 'Use the following as the webhook URL',
          code: datadogEndpointUrl,
          type: 'text'
        },
        {
          text: 'Use the following as the data for the webhook',
          code: datadogEndpointData,
          type: 'text'
        },
        {
          text: 'Optional fields in the data:',
          type: 'text',
          keyValueList: datadogOptionalFieldsExplanation
        }
      ]
    }
  ]

  const copyCodeToClipboard = code => {
    navigator.clipboard.writeText(code)
    GlobalConfig.showTransientAlert({
      message: I18n.t('apiCodeCopiedToClipboard')
    })
  }

  const InstructionSummary = ({
    index,
    text,
    code,
    keyValueList,
    type,
    instructions
  }) => {
    switch (type) {
      case 'list':
        return (
          <Box background="light-4" pad="small">
            {instructions.map((instruction, index) => {
              return (
                <InstructionSummary
                  key={index}
                  index={index}
                  {...instruction}
                />
              )
            })}
          </Box>
        )
      case 'text':
        return (
          <Box background="light-4" pad="small" gap="small">
            <Box direction="row" gap="xsmall">
              {isNumber(index) && <Text>{index + 1}</Text>}
              <Text style={{ whiteSpace: 'pre-line' }}>{text}</Text>
            </Box>

            {code && (
              <Box
                background="light-2"
                direction="row"
                margin={{ left: 'small' }}>
                <Box flex overflow="auto">
                  <Text margin="small" style={{ whiteSpace: 'pre-line' }}>
                    {code}
                  </Text>
                </Box>
                <Box pad="small" onClick={() => copyCodeToClipboard(code)}>
                  <Copy />
                </Box>
              </Box>
            )}

            {keyValueList && (
              <Box
                background="light-2"
                direction="row"
                margin={{ left: 'small' }}>
                <Box flex overflow="auto">
                  <Table margin="small">
                    <TableHeader>
                      <TableRow>
                        <TableCell scope="col" border="bottom">
                          {I18n.t('field')}
                        </TableCell>
                        <TableCell scope="col" border="bottom">
                          {I18n.t('description')}
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {keyValueList.map((keyValue, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell scope="row" verticalAlign="top">
                              <Text style={{ fontWeight: 'bold' }}>
                                {keyValue.key}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text>{keyValue.value}</Text>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            )}
          </Box>
        )
      case 'accordion':
        return (
          <Box background="light-2" pad="small">
            <Accordion>
              {instructions.map((instruction, index) => {
                return (
                  <AccordionPanel key={index} label={instruction.text}>
                    <InstructionSummary key={index} {...instruction} />
                  </AccordionPanel>
                )
              })}
            </Accordion>
          </Box>
        )
      default:
        console.error('Unknown instruction type', type)
        return null
    }
  }

  const onChangeAccordionIndex = index => {
    setAccordionIndex(index)
  }

  if (!apiKey || !authToken) {
    return <Spinner />
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box width="xlarge" height={{ max: 'large' }} overflow="auto">
        <Heading alignSelf="center" level={4}>
          {I18n.t('apiCodes')}
        </Heading>
        <SecondaryText>{I18n.t('apiCodesDescription')}</SecondaryText>
        <Accordion
          activeIndex={accordionIndex}
          onActive={onChangeAccordionIndex}>
          {codes.map((code, index) => {
            return (
              <AccordionPanel key={index} label={code.text}>
                <InstructionSummary key={index} {...code} />
              </AccordionPanel>
            )
          })}
        </Accordion>
      </Box>
      <Link
        style={{
          color: colors.blue,
          textDecoration: 'none',
          paddingLeft: 5,
          paddingTop: 20
        }}
        to="/app/feedback"
        state={{ title: I18n.t('requestIntegration') }}
        rel="noopener noreferrer">
        {I18n.t('requestIntegration')}
      </Link>
    </ErrorProvider>
  )
}

export default ViewApiCode
