import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Text,
  Heading,
  Anchor,
  Layer,
  Spinner,
  ResponsiveContext
} from 'grommet'
import { Add, Close, Subtract } from 'grommet-icons'
import { Constants, I18n } from 'galarm-config'
import NumSeatsHelper from './NumSeatsHelper'
import { getFunctions, httpsCallable } from 'firebase/functions'
import UserContext from './UserContext'
import { doc, getDoc } from 'firebase/firestore'
import { SecondaryText } from 'web-components'
import GlobalConfig from '../GlobalConfig'
import ComparePlans from './ComparePlans'

// Products
// {
//   "object": "list",
//   "data": [
//       {
//           "id": "prod_KlggWNK21mJTrt",
//           "object": "product",
//           "active": true,
//           "attributes": [],
//           "created": 1639381863,
//           "description": "Premium Subscription Tier 3",
//           "images": [],
//           "livemode": false,
//           "metadata": {},
//           "name": "Premium Subscription Tier 3",
//           "package_dimensions": null,
//           "shippable": null,
//           "statement_descriptor": null,
//           "tax_code": "txcd_10000000",
//           "type": "service",
//           "unit_label": "seat",
//           "updated": 1639397095,
//           "url": null
//       },
//       {
//           "id": "prod_KlggulKiklFi9u",
//           "object": "product",
//           "active": true,
//           "attributes": [],
//           "created": 1639381835,
//           "description": "Premium Subscription Tier 2",
//           "images": [],
//           "livemode": false,
//           "metadata": {},
//           "name": "Premium Subscription Tier 2",
//           "package_dimensions": null,
//           "shippable": null,
//           "statement_descriptor": null,
//           "tax_code": "txcd_10000000",
//           "type": "service",
//           "unit_label": "seat",
//           "updated": 1639397132,
//           "url": null
//       },
//       {
//           "id": "prod_KlgfKRlo7EbWk2",
//           "object": "product",
//           "active": true,
//           "attributes": [],
//           "created": 1639381774,
//           "description": "Premium Subscription Tier 1",
//           "images": [],
//           "livemode": false,
//           "metadata": {},
//           "name": "Premium Subscription Tier 1",
//           "package_dimensions": null,
//           "shippable": null,
//           "statement_descriptor": null,
//           "tax_code": "txcd_10000000",
//           "type": "service",
//           "unit_label": "seat",
//           "updated": 1639397155,
//           "url": null
//       }
//   ],
//   "has_more": false,
//   "url": "/v1/products"
// }

// Prices
// {
//   "object": "list",
//   "data": [
//       {
//           "id": "price_1K69JMKB9wCuxxPGkBRWrVnS",
//           "object": "price",
//           "active": true,
//           "billing_scheme": "per_unit",
//           "created": 1639381864,
//           "currency": "usd",
//           "livemode": false,
//           "lookup_key": null,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_KlggWNK21mJTrt",
//           "recurring": {
//               "aggregate_usage": null,
//               "interval": "month",
//               "interval_count": 1,
//               "trial_period_days": null,
//               "usage_type": "licensed"
//           },
//           "tax_behavior": "exclusive",
//           "tiers_mode": null,
//           "transform_quantity": null,
//           "type": "recurring",
//           "unit_amount": 999,
//           "unit_amount_decimal": "999"
//       },
//       {
//           "id": "price_1K69JMKB9wCuxxPGXN9nerkT",
//           "object": "price",
//           "active": true,
//           "billing_scheme": "per_unit",
//           "created": 1639381864,
//           "currency": "usd",
//           "livemode": false,
//           "lookup_key": null,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_KlggWNK21mJTrt",
//           "recurring": {
//               "aggregate_usage": null,
//               "interval": "year",
//               "interval_count": 1,
//               "trial_period_days": null,
//               "usage_type": "licensed"
//           },
//           "tax_behavior": "exclusive",
//           "tiers_mode": null,
//           "transform_quantity": null,
//           "type": "recurring",
//           "unit_amount": 9999,
//           "unit_amount_decimal": "9999"
//       },
//       {
//           "id": "price_1K69ItKB9wCuxxPGtmx3kY5J",
//           "object": "price",
//           "active": true,
//           "billing_scheme": "per_unit",
//           "created": 1639381835,
//           "currency": "usd",
//           "livemode": false,
//           "lookup_key": null,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_KlggulKiklFi9u",
//           "recurring": {
//               "aggregate_usage": null,
//               "interval": "month",
//               "interval_count": 1,
//               "trial_period_days": null,
//               "usage_type": "licensed"
//           },
//           "tax_behavior": "exclusive",
//           "tiers_mode": null,
//           "transform_quantity": null,
//           "type": "recurring",
//           "unit_amount": 399,
//           "unit_amount_decimal": "399"
//       },
//       {
//           "id": "price_1K69ItKB9wCuxxPGmJOapDAX",
//           "object": "price",
//           "active": true,
//           "billing_scheme": "per_unit",
//           "created": 1639381835,
//           "currency": "usd",
//           "livemode": false,
//           "lookup_key": null,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_KlggulKiklFi9u",
//           "recurring": {
//               "aggregate_usage": null,
//               "interval": "year",
//               "interval_count": 1,
//               "trial_period_days": null,
//               "usage_type": "licensed"
//           },
//           "tax_behavior": "exclusive",
//           "tiers_mode": null,
//           "transform_quantity": null,
//           "type": "recurring",
//           "unit_amount": 3999,
//           "unit_amount_decimal": "3999"
//       },
//       {
//           "id": "price_1K69HvKB9wCuxxPGfL1Ez4kO",
//           "object": "price",
//           "active": true,
//           "billing_scheme": "per_unit",
//           "created": 1639381775,
//           "currency": "usd",
//           "livemode": false,
//           "lookup_key": null,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_KlgfKRlo7EbWk2",
//           "recurring": {
//               "aggregate_usage": null,
//               "interval": "year",
//               "interval_count": 1,
//               "trial_period_days": null,
//               "usage_type": "licensed"
//           },
//           "tax_behavior": "exclusive",
//           "tiers_mode": null,
//           "transform_quantity": null,
//           "type": "recurring",
//           "unit_amount": 999,
//           "unit_amount_decimal": "999"
//       },
//       {
//           "id": "price_1K69HvKB9wCuxxPGvf0Z4pYo",
//           "object": "price",
//           "active": true,
//           "billing_scheme": "per_unit",
//           "created": 1639381775,
//           "currency": "usd",
//           "livemode": false,
//           "lookup_key": null,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_KlgfKRlo7EbWk2",
//           "recurring": {
//               "aggregate_usage": null,
//               "interval": "month",
//               "interval_count": 1,
//               "trial_period_days": null,
//               "usage_type": "licensed"
//           },
//           "tax_behavior": "exclusive",
//           "tiers_mode": null,
//           "transform_quantity": null,
//           "type": "recurring",
//           "unit_amount": 99,
//           "unit_amount_decimal": "99"
//       }
//   ],
//   "has_more": false,
//   "url": "/v1/prices"
// }

const Counter = ({ count, onChange }) => {
  return (
    <Box direction="row" align="center">
      <Button
        icon={<Subtract color="red" />}
        onClick={() => onChange(count - 1)}
        disabled={count === 0}
        hoverIndicator
      />
      <Text>{count}</Text>
      <Button
        icon={<Add color="plain" />}
        onClick={() => onChange(count + 1)}
        hoverIndicator
      />
    </Box>
  )
}

const BillingCycle = ({ product, chosenInterval, onChange }) => {
  console.log('product', product)
  return (
    <Box
      direction="row"
      align="center"
      gap="medium"
      background="lightPrimary"
      round="large"
      pad={{ horizontal: 'small', vertical: 'small' }}>
      {product.prices.map((price, index) => {
        const isChosenInterval = price.interval === chosenInterval
        const priceInUsd = price.amount / 100
        const priceString = priceInUsd.toLocaleString('en-US', {
          style: 'currency',
          currency: price.currency
        })
        console.log('priceString', priceString)
        return (
          <Box
            key={index}
            background={isChosenInterval ? 'primary' : 'lightTint'}
            pad={{ horizontal: 'small', vertical: 'xsmall' }}
            round="medium"
            onClick={() => onChange(price.interval)}>
            <Text color={isChosenInterval ? 'white' : 'darkTint'}>
              {price.metadata.displayString || priceString}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

const PlanType = ({ planTypes, chosenPlanType, onChange }) => {
  return (
    <Box
      direction="row"
      align="center"
      gap="medium"
      background="lightPrimary"
      round="large"
      pad={{ horizontal: 'small', vertical: 'small' }}>
      {planTypes.map((planType, index) => {
        const isChosenPlanType = planType.value === chosenPlanType
        return (
          <Box
            key={index}
            background={isChosenPlanType ? 'primary' : 'lightTint'}
            pad={{ horizontal: 'small', vertical: 'xsmall' }}
            round="medium"
            onClick={() => onChange(planType.value)}>
            <Text color={isChosenPlanType ? 'white' : 'darkTint'}>
              {planType.label}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

// eslint-disable-next-line no-unused-vars
const SubscriptionCard = ({ product, interval, onSubscribe }) => {
  const [numItems, setNumItems] = useState(0)

  const onChoose = () => {
    onSubscribe(product, numItems)
  }

  const productPrice = product.prices.find(price => price.interval === interval)
  let price = productPrice.amount / 100
  price = price.toLocaleString('en-US', {
    style: 'currency',
    currency: productPrice.currency
  })

  const priceString = `${price}/${product.unit_label}`

  return (
    <Card background="light-1">
      <CardHeader background="lightPrimary" pad="medium">
        {product.name}
      </CardHeader>
      <CardBody pad="medium">{product.description}</CardBody>
      <Heading level={5} textAlign="center">
        {priceString}
      </Heading>
      <CardFooter pad={{ horizontal: 'small' }} background="light-2">
        <Counter count={numItems} onChange={setNumItems} />
        <Button
          disabled={numItems === 0}
          label={I18n.t('subscribe')}
          onClick={onChoose}
          hoverIndicator
        />
      </CardFooter>
    </Card>
  )
}

const ChoosePlan = ({ onChoosePlan }) => {
  const { user } = useContext(UserContext)
  const size = useContext(ResponsiveContext)
  const [trialAvailable, setTrialAvailable] = useState(true)
  const [products, setProducts] = useState([])
  const [numItems, setNumItems] = useState(0)
  const [showNumSeatsHelper, setShowNumSeatsHelper] = useState(false)
  const [showPlansComparison, setShowPlansComparison] = useState(false)
  const [planType, setPlanType] = useState(
    Constants.ENTERPRISE_PLAN_TYPES[0].value
  )
  const [interval, setInterval] = useState(
    Constants.SUBSCRIPTION_INTERVALS.YEARLY
  )

  const displayNumSeatsHelper = () => {
    setShowNumSeatsHelper(true)
  }

  const hideNumSeatsHelper = () => {
    setShowNumSeatsHelper(false)
  }

  const hidePlansComparison = () => {
    setShowPlansComparison(false)
  }

  const displayPlansComparison = () => {
    setShowPlansComparison(true)
  }

  useEffect(() => {
    async function loadSubscriptionData() {
      try {
        const functions = getFunctions()

        const stripeListProducts = httpsCallable(
          functions,
          'stripeListProducts'
        )
        const resultProducts = await stripeListProducts()
        const products = resultProducts.data.products
        console.log('products', products)

        const stripeListPrices = httpsCallable(functions, 'stripeListPrices')
        const resultPrices = await stripeListPrices()
        const prices = resultPrices.data.prices
        console.log('prices', prices)

        const finalProducts = []
        products.forEach(product => {
          const productPrices = prices.filter(
            price => price.product === product.id
          )
          finalProducts.push({
            ...product,
            prices: productPrices
          })
        })
        setProducts(finalProducts)

        const enterpriseUser = await getDoc(
          doc(GlobalConfig.firestoreDb, 'enterpriseUsers', user.uid)
        ).then(userSnapshot => userSnapshot.data())

        if (enterpriseUser.availedTrial) {
          setTrialAvailable(false)
        }
      } catch (error) {
        console.error('Error loading subscription data', error)
      }
    }
    loadSubscriptionData()
  }, [])

  const onSubscribe = async () => {
    onChoosePlan({
      product: products.find(product => product.metadata.planType === planType),
      numItems,
      interval
    })
  }

  if (products.length === 0) {
    return (
      <Box align="center" justify="center">
        <Spinner />
      </Box>
    )
  }

  return (
    <Box align="center" flex={{ shrink: 0 }}>
      <Heading level={4} margin={{ left: 'medium' }}>
        {I18n.t('choosePlan')}
      </Heading>
      <Box
        direction={size === 'small' ? 'column' : 'row'}
        align="center"
        justify="start"
        gap="medium"
        margin="medium">
        <Text weight="500">{I18n.t('planType')}</Text>
        <PlanType
          planTypes={Constants.ENTERPRISE_PLAN_TYPES}
          chosenPlanType={planType}
          onChange={setPlanType}
        />
        <Anchor onClick={displayPlansComparison} size="small">
          {I18n.t('compare')}
        </Anchor>
      </Box>
      <Box
        direction={size === 'small' ? 'column' : 'row'}
        align="center"
        justify="start"
        gap="medium"
        margin="medium">
        <Text weight="500">{I18n.t('billingCycle')}</Text>
        <BillingCycle
          product={products.find(
            product => product.metadata.planType === planType
          )}
          chosenInterval={interval}
          onChange={setInterval}
        />
      </Box>
      <Box
        direction={size === 'small' ? 'column' : 'row'}
        align="center"
        justify="start"
        gap="medium"
        margin="medium">
        <Text weight="500">{I18n.t('numSeats')}</Text>
        <Box direction="row" align="center" gap="small">
          <Counter count={numItems} onChange={setNumItems} />
          <Anchor onClick={displayNumSeatsHelper} size="small">
            {I18n.t('helpMeDecide')}
          </Anchor>
        </Box>
      </Box>
      <Box direction="row" justify="center" margin="medium">
        <Button
          primary
          disabled={numItems === 0}
          label={trialAvailable ? I18n.t('startTrial') : I18n.t('subscribe')}
          onClick={onSubscribe}
          hoverIndicator
          style={{ color: 'white' }}
        />
      </Box>
      <SecondaryText margin={{ left: 'medium' }}>
        {trialAvailable
          ? I18n.t('galarmEnterpriseTrialDescription')
          : I18n.t('galarmEnterpriseTrialAlreadyAvailed')}
      </SecondaryText>
      {showNumSeatsHelper && (
        <Layer onClickOutside={hideNumSeatsHelper} onEsc={hideNumSeatsHelper}>
          <NumSeatsHelper onClose={hideNumSeatsHelper} />
        </Layer>
      )}
      {showPlansComparison && (
        <Layer
          onClickOutside={hidePlansComparison}
          onEsc={hidePlansComparison}
          margin="medium">
          <Box margin="medium">
            <ComparePlans onClose={hidePlansComparison} />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hidePlansComparison} />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  )
}

export default ChoosePlan
