import { I18n } from 'galarm-config'
import { Box, FormField, TextInput } from 'grommet'
import React from 'react'
import { SecondaryText } from 'web-components'

const EditDaysRotations = ({ value, onUpdateRotation }) => {
  const onChangeNumDays = event => {
    onUpdateRotation({
      ...value,
      rotationParams: {
        numDaysInShift: event.target.value
      }
    })
  }

  return (
    <Box>
      <FormField
        htmlFor="rotationParams.numDaysInShift"
        name="rotationParams.numDaysInShift"
        label={
          <SecondaryText weight="500">
            {I18n.t('numberOfDaysInRotation')}
          </SecondaryText>
        }
        // validate={stringNotEmpty.bind(null, I18n.t('numberOfDaysInRotation'))}
      >
        <TextInput
          id="rotationParams.numDaysInShift"
          name="rotationParams.numDaysInShift"
          placeholder={I18n.t('specify')}
          onChange={onChangeNumDays}
          value={value.rotationParams.numDaysInShift}
        />
      </FormField>
    </Box>
  )
}

export default EditDaysRotations
