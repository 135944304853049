import Constants from './Constants'
import { Platform, Appearance, PixelRatio } from 'react-native'
import I18n from './translations/i18nTranslations'

// All the intervals are in msecs

// Following properties are push into this object
// uid
// username
// alarmRingtone
// ringParticipantAlarmsByDefault

const GlobalConfig = (function () {
  const ringtones = [
    {
      label: I18n.t('random'),
      value: Constants.RANDOM_RINGTONE_VALUE,
      type: 'inbuilt',
      requiresPremium: true
    },
    {
      label: I18n.t('beeps'),
      value: 'beeps',
      type: 'inbuilt',
      requiresPremium: false
    },
    {
      label: I18n.t('bollywood_nights'),
      value: 'bollywood_nights',
      type: 'inbuilt',
      requiresPremium: true
    },
    {
      label: I18n.t('gentle_reminder'),
      value: 'gentle_reminder',
      type: 'inbuilt',
      requiresPremium: false
    },
    {
      label: I18n.t('himalayan_piper'),
      value: 'himalayan_piper',
      type: 'inbuilt',
      requiresPremium: false
    },
    {
      label: I18n.t('holiday_joy'),
      value: 'holiday_joy',
      type: 'inbuilt',
      requiresPremium: true
    },
    {
      label: I18n.t('hollywood_adventure'),
      value: 'hollywood_adventure',
      type: 'inbuilt',
      requiresPremium: false
    },
    {
      label: I18n.t('horizon'),
      value: 'horizon',
      type: 'inbuilt',
      requiresPremium: true,
      isNew: true
    },
    {
      label: I18n.t('melody'),
      value: 'melody',
      type: 'inbuilt',
      requiresPremium: true
    },
    {
      label: I18n.t('morning_rooster'),
      value: 'morning_rooster',
      type: 'inbuilt',
      requiresPremium: true
    },
    {
      label: I18n.t('morning_sunshine'),
      value: 'morning_sunshine',
      type: 'inbuilt',
      requiresPremium: true
    },
    {
      label: I18n.t('summer_soft'),
      value: 'summer_soft',
      type: 'inbuilt',
      requiresPremium: false
    },
    {
      label: I18n.t('sweet_sea'),
      value: 'sweet_sea',
      type: 'inbuilt',
      requiresPremium: true
    },
    {
      label: I18n.t('symphony'),
      value: 'symphony',
      type: 'inbuilt',
      requiresPremium: true,
      isNew: true
    },
    {
      label: I18n.t('old_school_ring'),
      value: 'old_school_ring',
      type: 'inbuilt',
      requiresPremium: true
    }
  ]

  Platform.OS === 'ios' &&
    ringtones.push({
      label: I18n.t('silent'),
      value: Constants.SILENT_RINGTONE_VALUE,
      type: 'inbuilt',
      requiresPremium: false
    })

  const defaultAlarmRingtone = {
    label: I18n.t('summer_soft'),
    value: 'summer_soft',
    type: 'inbuilt'
  }

  const notificationSounds = [
    {
      label: 'Xylo',
      value: 'beep_xylo',
      type: 'inbuilt'
    }
  ]

  const defaultNotificationSound = {
    label: 'Xylo',
    value: 'beep_xylo',
    type: 'inbuilt'
  }

  const systemAlarmCategories = [
    {
      name: I18n.t('workAlarmCategory'),
      color: 'orange',
      source: Constants.ALARM_CATEGORY_SOURCES.SYSTEM
    },
    {
      name: I18n.t('homeAlarmCategory'),
      color: 'blue',
      source: Constants.ALARM_CATEGORY_SOURCES.SYSTEM
    },
    {
      name: I18n.t('birthdaysAlarmCategory'),
      color: 'pink',
      source: Constants.ALARM_CATEGORY_SOURCES.SYSTEM
    }
  ]

  let defaultColorScheme
  const systemColorScheme = Appearance.getColorScheme()
  if (systemColorScheme === 'dark') {
    defaultColorScheme = Constants.COLOR_SCHEMES.AUTO_DARK
  } else {
    defaultColorScheme = Constants.COLOR_SCHEMES.AUTO_LIGHT
  }

  // These are the settings for which default has been updated
  // Keep in sync with the actual default values
  const updatedDefaultUserSettings = {
    graduallyIncreaseVolume: true
  }

  const alarmDatesMap = new Map()

  return {
    test: __DEV__ && false,
    testUser: '-LI-RNzLGkansBEocXq9',
    defaultCascadingAlarmInterval: '15mins',
    defaultRecipientAlarmInterval: '15mins',
    defaultCalendarDuration: 1800000,
    expiredAlarmThresholdForIos: 30 * Constants.MSEC_IN_MINUTE,
    syncContactsInterval: 300000,
    alarmPreviousOccurrenceThreshold: 43200000, // Extend an alarm for 12 hours after expiration
    periodicFetchIntervalForAndroid: 600000,
    upcomingAlarmInterval: 86400000,
    appSecret: 'w_3hd%w&*ky$(*tz-_i(nl_$8alyryu(!$tt07f=yw_b3&w8yt',
    appHashDebug: 'vhyNRrPz1f/',
    appHashRelease: 'A+74wDt10nC',
    GCM_SENDER_ID: '490968901272',
    ringtones: ringtones,
    notificationSounds: notificationSounds,
    defaultAlarmRingtone: defaultAlarmRingtone,
    defaultNotificationSound: defaultNotificationSound,
    defaultValueForRingParticipantAlarmsByDefault: true,
    minIntervalBetweenConsecutiveRateTheAppRequests: 604800000,
    defaultSnoozeInterval: 600000,
    imageAgeOutInterval: 60000,
    numPastAlarmsToShow: 5,
    maxAllowedAlarms: Platform.select({ android: 500, ios: 500, web: 500 }),
    maxAllowedAlarmsBeforeAds: 3,
    defaultRingOnVibrate: Platform.select({
      android: true,
      ios: false,
      web: true
    }),
    defaultRingtoneDuration: 60000,
    defaultTimeFormat: Constants.TimeFormats.TWELVE_HOUR_FORMAT,
    contactRefreshDebounceInterval: 60000,
    numConversationMessagesToLoadInOneRequest: 20,
    maxAlarmNameLengthForScreenTitle: Math.ceil(18 / PixelRatio.getFontScale()),
    intervalBetweenWelcomeAlarm: 300000,
    resetUserIsTypingInterval: 5000,
    maxResetUserIsTypingInterval: 60000,
    maxIntervalBetweenTwoFullContactsSync: 604800000,
    maxNumberOfContactsToAllowAutoSync: 5000,
    maxIntervalBetweenRemindMeLater: 604800000,
    ageOutInactiveAlarmsInterval: 28 * Constants.MSEC_IN_DAY,
    alarmVersion: 8,
    defaultPreReminderDuration: 0,
    defaultVibrate: true,
    defaultRingOnEarphoneOnly: false,
    defaultGraduallyIncreaseVolume: true,
    defaultGestureToDismissAlarm: Constants.GESTURES_TO_DISMISS_AN_ALARM.TAP,
    defaultNotificationSettings: {
      alarmsfromOthersNotifications: true,
      alarmAcknowledgementNotifications: true,
      alarmChatNotifictions: true,
      groupNotifications: true,
      sharedAlarmNotifications: true,
      galarmWebNotifications: true,
      systemNotifications: true
    },
    defaultSpecifyTimezoneForAlarm: false,
    defaultTimezoneForAlarmDefaultValue: I18n.t('deviceTimezone'),
    defaultVolume: 80,
    defaultCriticalAlerts: true,
    defaultCriticalAlertsVolume: 80,
    displayingOverlays: 0,
    defaultAlarmEndDate: 0,
    defaultAutoSnooze: true,
    defaultAutoSnoozeDuration: 600000,
    defaultAutoSnoozeCount: Platform.select({
      android: 3,
      ios: 2,
      web: 3
    }),
    defaultAnnounceAlarmName: false,
    defaultFirstDayOfWeek: 0,
    numPreviousOccurrencesForFreeAccount: 7,
    maxAllowedShareableAlarmLinks: 5,
    maxAllowedAlarmsSavedToCalendarCount: 5,
    maxAllowedInstantAlarms: 5,
    defaultColorScheme: defaultColorScheme,
    listWindowSize: 7,
    listUpdateCellsBatchingPeriod: 100,
    systemAlarmCategories: systemAlarmCategories,
    alarmDatesMap,
    maxNumParticipantsForFullNotifications: 20,
    maxProfileImageSize: 10485760,
    iosPendingNotificationsRequestsThreshold: 54,
    minIntervalBetweenUserTips: 1000,
    updatedDefaultUserSettings: updatedDefaultUserSettings
  }
})()

export default GlobalConfig
