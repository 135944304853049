import { DateTimeUtils } from 'galarm-shared'
import { Box, Text, TextArea } from 'grommet'
import React from 'react'

// Shape of remarks:
// {
//   id: string,
//   text: string,
//   timestamp: number,
//   user: {
//     id: string,
//     displayName: string
//   }
// }

const RemarksList = ({ remarks }) => {
  return (
    <Box gap="small" flex={{ shrink: 0 }}>
      {remarks.map(remark => (
        <Box key={remark.id} gap="xsmall">
          {remark.timestamp && remark.user && (
            <Box direction="row" gap="small">
              <Text size="small" weight="500">
                {remark.user?.displayName}
              </Text>
              <Text size="small" color="darkTint">
                {DateTimeUtils.getDateTimeAsString(remark.timestamp)}
              </Text>
            </Box>
          )}
          <TextArea
            value={remark.text}
            disabled
            style={{ resize: 'none', fieldSizing: 'content' }}
          />
        </Box>
      ))}
    </Box>
  )
}

export default RemarksList
