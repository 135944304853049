import { Constants, I18n } from 'galarm-config'
import GlobalConfig from '../GlobalConfig'
import { Box, Button, Form, FormField, Heading, TextInput } from 'grommet'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  stringNotEmpty,
  validateConfirmedPassword,
  validatePassword
} from '../utils/validations'
import ErrorProvider from './ErrorProvider'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth, signOut } from 'firebase/auth'
import { SecondaryText } from 'web-components'

const ChangePassword = () => {
  const [value, setValue] = useState({
    currPassword: '',
    newPassword: '',
    confirmPassword: ''
  })

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const navigate = useNavigate()

  const onChangePassword = async ({ value }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('changingPassword'),
      closeable: false
    })

    const { currPassword, newPassword } = value
    console.log('onChangePassword', value)
    try {
      const functions = getFunctions()
      const changePassword = httpsCallable(functions, 'changePassword')
      await changePassword({
        currPassword,
        newPassword
      })
      GlobalConfig.hideProgress()
      const auth = getAuth()
      await signOut(auth)
      window.localStorage.removeItem('enterpriseAccountId')
      navigate('/login', { replace: true })
    } catch (error) {
      console.error('Unable to change password', error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center">
        <Heading level={4} margin={{ left: 'medium' }}>
          {I18n.t('changePassword')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={onChangePassword}>
            <FormField
              htmlFor="currPassword"
              name="currPassword"
              label={
                <SecondaryText weight="500">
                  {I18n.t('currPassword')}
                </SecondaryText>
              }
              validate={stringNotEmpty.bind(null, I18n.t('currPassword'))}>
              <TextInput
                type="password"
                id="currPassword"
                name="currPassword"
              />
            </FormField>
            <FormField
              htmlFor="newPassword"
              name="newPassword"
              label={
                <SecondaryText weight="500">
                  {I18n.t('newPassword')}
                </SecondaryText>
              }
              validate={validatePassword}>
              <TextInput type="password" id="newPassword" name="newPassword" />
            </FormField>
            <FormField
              htmlFor="confirmPassword"
              name="confirmPassword"
              label={
                <SecondaryText weight="500">
                  {I18n.t('confirmPassword')}
                </SecondaryText>
              }
              validate={(confirmPassword, formValue) =>
                validateConfirmedPassword(
                  formValue.newPassword,
                  confirmPassword
                )
              }>
              <TextInput
                type="password"
                id="confirmPassword"
                name="confirmPassword"
              />
            </FormField>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={I18n.t('change')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default ChangePassword
